<template>
  <div class="skeleton-loader" :style="skeletonStyle">
    <div class="shimmer" :style="shimmerStyle"></div>
    <slot />
  </div>
</template>

<script setup lang="ts">
const LOADER_TYPES = ['rectangle', 'circle'] as const
const SHIMMER_COLOR = '#ffffff'

const props = withDefaults(
  defineProps<{
    type?: (typeof LOADER_TYPES)[number]
    shimmerColor?: string
    bgColor?: string
    // percent or px width
    width?: string
    // height in px
    height?: string
    // margin px or var
    marginBottom?: string
    // radius px or var
    radius?: string
    opacity?: number
    shimmerOpacity?: number
  }>(),
  {
    type: 'rectangle',
    shimmerColor: '#ffffff',
    bgColor: '--grey-300',
    width: '100%',
    height: '50px',
    marginBottom: '0px',
    radius: '6px',
    opacity: 1,
    shimmerOpacity: 1,
  },
)

const {
  type,
  bgColor,
  shimmerColor,
  width,
  height,
  marginBottom,
  radius,
  opacity,
  shimmerOpacity,
} = toRefs(props)

const isHexColor = (hexColor: string) => {
  const hex = hexColor.replace('#', '')

  return (
    typeof hexColor === 'string' &&
    hexColor.startsWith('#') &&
    hex.length === 6 &&
    !Number.isNaN(Number(`0x${hex}`))
  )
}
const hexToRgb = (hex: string) => `${hex.match(/\w\w/g)?.map((x) => +`0x${x}`)}`

const shimmerStyle = computed(() => {
  const rgb = isHexColor(shimmerColor.value)
    ? hexToRgb(shimmerColor.value)
    : SHIMMER_COLOR

  return {
    backgroundImage: `linear-gradient(90deg, rgba(${rgb}, 0) 0%, rgba(${rgb}, ${
      0.2 * shimmerOpacity.value
    }) 20%, rgba(${rgb},${0.5 * shimmerOpacity.value}) 60%, rgba(${rgb}, 0))`,
  }
})
const skeletonStyle = computed(() => ({
  'background-color': bgColor.value?.startsWith('--')
    ? `rgba(var(${bgColor.value}))`
    : bgColor.value,
  'border-radius': type.value === 'circle' ? '50%' : radius.value,
  width: width.value,
  height: height.value,
  'margin-bottom': marginBottom.value?.startsWith('--')
    ? `var(${marginBottom.value})`
    : marginBottom.value,
  opacity: opacity.value,
}))
</script>

<style scoped>
.skeleton-loader {
  position: relative;
  transform: translateZ(0);

  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.shimmer {
  position: absolute;
  inset: 0;
  transform: translateX(-100%);
  animation: shimmer 1.6s infinite;
}

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}
</style>
